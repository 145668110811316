import PresentationSection from '@/components/layout/Sections/PresentationSection';

import CarouselSection from '@/components/layout/Sections/CarouselSection';
import AboutUsSection from '@/components/layout/Sections/AboutUsSection';
import ImportantContentSection from '@/components/layout/Sections/ImportantContentSection';
import PageLayout from '@/components/layout/page-layout';
import PageHead from '@/components/PageHead';
import ProjectsSection from '@/components/layout/Sections/ProjectsSection';
import { CarouselPartnerships } from '@/components/layout/Sections/CarouselPartnerships';
import { SocialInvestorSection } from '@/components/layout/Sections/SocialInvestorSection';
import DonorSection from '@/components/layout/Sections/DonorSection';
import { YoutubeEmbed } from '@/components/YoutubeEmbed';
import PresentationSection2 from '@/components/layout/Sections/PresentationSection2';
import YoutubeCarouselSection from '@/components/layout/Sections/YoutubeCarouselSection';

export default function Page() {
	return (
		<>
			<PageHead
				page={{
					title: 'Instituto Deixe Vivo',
					keywords:
						'OSC, Instituto, Deixe Vivo, Doação de órgãos e tecidos, transplante, transplantados, transplantes, renal, tecidos, doença renal',
					description:
						'Informação, Inspiração e Acolhimento. Nosso trabalho é focado em conscientizar toda a sociedade sobre a doação de órgãos e tecidos',
					robots: 'follow, index',
					colorScheme: 'light dark',
				}}
				og={{
					description:
						'Informação, Inspiração e Acolhimento. Nosso trabalho é focado em conscientizar toda a sociedade sobre a doação de órgãos e tecidos',
					site_name: 'Instituto Deixe Vivo',
					title: 'Instituto Deixe Vivo',
					type: 'website',
					url: 'https://deixevivo.org.br',
					image: {
						alt: 'Logo do Instituto, ao lado esquerdo da imagem um rosto feliz e ao lado direito texto que diz Instituto Deixe Vivo',
						fbAdmins: 'instituto.deixevivo',
						src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1681919729/InstitutoDeixeVivo/logos/logo-deixe-vivo-1200x1200.jpg',
						type: 'image/jpg',
						width: '1200',
						height: '1200',
					},
				}}
				twitter={{
					card: 'summary',
					description:
						'Informação, Inspiração e Acolhimento. Nosso trabalho é focado em conscientizar toda a sociedade sobre a doação de órgãos e tecidos',
					site: 'Instituto Deixe Vivo',
					title: 'Instituto Deixe Vivo',
					url: 'https://deixevivo.org.br',
					image: {
						alt: 'Logo do Instituto, ao lado esquerdo da imagem um rosto feliz e ao lado direito texto que diz Instituto Deixe Vivo',
						src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1681919729/InstitutoDeixeVivo/logos/logo-deixe-vivo-1200x1200.jpg',
					},
				}}
			/>

			<PageLayout>
				<CarouselSection />
				<PresentationSection2 />
				<PresentationSection />

				{/* <AboutUsSection /> */}
				{/* <YoutubeEmbed /> */}
				<YoutubeCarouselSection />
				<ImportantContentSection />
				{/* <DonorSection /> */}
				<SocialInvestorSection />
				<CarouselPartnerships />
			</PageLayout>
		</>
	);
}
{/* <ProjectsSection /> */}
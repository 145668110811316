import { NextArrow, PrevArrow } from '@/components/CarouselArrows';
import Container from '@/components/Container';
import NextLink from 'next/link';
import { BsYoutube } from 'react-icons/bs';

import Slider, { Settings } from 'react-slick';

export default function YoutubeCarouselSection() {
	const carouselSettings: Settings = {
		accessibility: true,
		arrows: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		dots: true,
		pauseOnHover: true,
		speed: 4000,
		swipe: true,
		touchMove: true,
		infinite: true,
		autoplay: true,
		pauseOnFocus: true,
	};

	const carouselImages = [
		{
			href: 'https://youtu.be/JcV5UMc5XDQ',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep1.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep1.png',
				alt: 'Episódio 1',
			},
		},
		{
			href: 'https://youtu.be/OwVpLxUh_go',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep2.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep2.png',
				alt: 'Episódio 2',
			},
		},
		{
			href: 'https://youtu.be/Xlagh9AUVBU',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep3.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep3.png',
				alt: 'Episódio 3',
			},
		},
		{
			href: 'https://youtu.be/fkopEubQCBA',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep4.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep4.png',
				alt: 'Episódio 4',
			},
		},
		{
			href: 'https://youtu.be/JucFMOIFZ1s',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep5.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094687/InstitutoDeixeVivo/videos/youtube/ep5.png',
				alt: 'Episódio 5',
			},
		},
		{
			href: 'https://youtu.be/aJ4tgR0I0zw',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094690/InstitutoDeixeVivo/videos/youtube/vlog-evento.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094690/InstitutoDeixeVivo/videos/youtube/vlog-evento.png',
				alt: 'Episódio 6',
			},
		},
		{
			href: 'https://youtu.be/A2QdNWbdhPw',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep6.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep6.png',
				alt: 'Episódio 7',
			},
		},
		{
			href: 'https://youtu.be/GQ23UVpUZAY',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep7.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep7.png',
				alt: 'Episódio 8',
			},
		},
		{
			href: 'https://youtu.be/nyGRk19L1Bo',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep8.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep8.png',
				alt: 'Episódio 9',
			},
		},
		{
			href: 'https://youtu.be/ul6oitTufQM',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep9.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep9.png',
				alt: 'Episódio 10',
			},
		},
		{
			href: 'https://youtu.be/EtymRueHJIA',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep10.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1736094688/InstitutoDeixeVivo/videos/youtube/ep10.png',
				alt: 'Episódio 11',
			},
		},
	];

	return (
		<>
			<Container className="py-8">
				<div className="mt-8">
					<b className="text-3xl font-bold mb-2 block">Conheça nosso Podcast</b>

					<h2 className="text-2xl font-bold mt-2 mb-8">
						#deixevivo Talks - conversas inspiradoras
					</h2>
				</div>
			</Container>

			<section
				id="carousel-section"
				className="md:min-h-[500px] w-full relative mx-auto"
			>
				<Slider {...carouselSettings}>
					{carouselImages.map((v, i) => (
						<NextLink
							key={i}
							className="w-full h-full relative"
							target="_blank"
							href={v.href}
						>
							<figure className="relative">
								<picture>
									<source srcSet={v.images.mobile} media="(max-width: 480px)" />
									<source
										srcSet={v.images.desktop}
										media="(min-width: 1200px)"
										className="relative"
									/>
									<img
										className="object-cover"
										src={v.images.mobile}
										alt={v.images.alt}
										width={1920}
										height={360}
									/>
								</picture>
								<figcaption className="invisible"></figcaption>
								<BsYoutube
									className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] fill-[#FF0000]"
									size={128}
								/>
							</figure>
						</NextLink>
					))}
				</Slider>
			</section>
		</>
	);
}

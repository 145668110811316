import { useState } from 'react';
import { AlertProps } from './Alert';
import ModalDocuments from '@/components/ModalDocuments';

type InformativeFormProps = {
	isDialogOpened: boolean;
	setIsDialogOpened: (state: boolean) => void;
	Closable: JSX.Element;
};

export default function InformativeForm({
	isDialogOpened,
	setIsDialogOpened,
	Closable,
}: InformativeFormProps) {
	const [alertState, setAlertState] = useState({} as AlertProps);
	const [isOpenTermos, setIsOpenTermos] = useState(false);
	const permittedKeys = Array.from({ length: 10 }).map((v, k) => `${k}`);
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

	const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const formData = new FormData(event.currentTarget);

		setIsSubmitButtonDisabled(true);

		let data:
			| {
					email: string;
					fullName: string;
					newsletter: string | boolean;
					phoneNumber: string;
					terms_policy: string | boolean;
					youAreOption: string;
			  }
			| {} = {};

		for (let d of formData) {
			if (d[0] == 'terms_policy') {
				data[d[0]] = true;
				continue;
			}
			if(d[0] == 'newsletter') {
				data[d[0]] = d[1];
				continue;
			}
			data[d[0]] = d[1];
		}
		
		try {
			const response = await fetch(`/api/v1/public/informative`, {
				method: 'POST',
				body: JSON.stringify(data),
			});

			if (response.status == 201) {
				const { body } = await response.json();
				console.log(body)
				setTimeout(() => {
					window.open(
						`/informativo-citomegalovirus?token=${body.token}`,
						'_SELF',
					);
				}, 3000);
			}
			
			if(response.status == 400) {
				const { body } = await response.json();
				setTimeout(() => {
					window.open(
						`/informativo-citomegalovirus?token=${body.data.token}`,
						'_SELF',
					);
				})
			}

		} catch (e) {}
	};

	return (
		<>
			<div className="w-[100svw] h-[100svh] bg-slate-800/80 z-[9999] fixed top-0 left-0 p-4  overflow-y-auto">
				<dialog
					open={isDialogOpened}
					className="p-4 px-4 dark:bg-white dark:text-black bg-white md:w-[50%] mx-auto rounded-md w-[90%]"
				>
					<div className="flex justify-end">{Closable}</div>

					<div>
						<h3 className="text-black font-bold pt-2">
							Preencha o formulário para ter acesso ao conteúdo.
						</h3>
					</div>
					<form
						id="form-informative"
						className="w-[100%]"
						onSubmit={handleFormSubmit}
					>
						<fieldset className="border-2 p-4">
							<legend className="font-bold">Seus dados:</legend>
							<label className="block" htmlFor="nome">
								<span className="block">Nome Completo:</span>
								<input
									className="w-full h-8 p-2 border-b-2 dark:bg-white"
									id="nome"
									name="fullName"
									type="text"
									required
									minLength={5}
									maxLength={40}
								/>
							</label>
							<label className="mt-2 block" htmlFor="email">
								<span className="block">Email:</span>
								<input
									className="w-full h-8 p-2 border-b-2 dark:bg-white"
									id="email"
									name="email"
									type="email"
									required
									minLength={10}
									maxLength={40}
									onChange={(event) => {
										let value = event.currentTarget.value;
										if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
											return;
										}

										return false;
									}}
								/>
							</label>
							<label className="mt-2 block" htmlFor="telefone">
								<span className="block">Nº Telefone (Whastapp):</span>
								<input
									className="w-full h-8 p-2 border-b-2 dark:bg-white"
									id="phoneNumber"
									name="phoneNumber"
									type="tel"
									required
									minLength={10}
									maxLength={11}
									onChange={(event) => {
										event.currentTarget.value =
											event.currentTarget.value.replace(/\D/, '');
									}}
								/>
							</label>
						</fieldset>

						<fieldset className="border-2 p-4">
							<legend className="font-bold">
								<span>Você é:</span>
							</legend>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Paciente em lista de espera para transplante"
									required
								/>
								<label>Paciente em lista de espera para transplante</label>
							</div>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Paciente transplantado (a menos de 90 dias)"
									required
								/>
								<label>Paciente transplantado (a menos de 90 dias)</label>
							</div>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Paciente transplantado (a mais de 90 dias)"
									required
								/>
								<label>Paciente transplantado (a mais de 90 dias)</label>
							</div>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Paciente transplantado (a mais de 1 ano)"
									required
								/>
								<label>Paciente transplantado (a mais de 1 ano)</label>
							</div>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Profissional da área da Saúde"
									required
								/>
								<label>Profissional da área da Saúde</label>
							</div>
							<div>
								<input
									className="mr-4"
									id="youAreOption"
									name="youAreOption"
									type="radio"
									value="Familiar/Amigo(a) de paciente"
									required
								/>
								<label>Familiar/Amigo(a) de paciente</label>
							</div>
						</fieldset>

						<fieldset className="border-2 p-4">
							<legend className="font-bold">
								<span>Aceites:</span>
							</legend>

							<div>
								<input
									required
									id="terms_policy"
									type="checkbox"
									name="terms_policy"
									onChange={(event) => {
										event.target.value == 'on' ? true : false;
										console.log(event.target.value);
									}}
								/>
								<label htmlFor="terms_policy" className="ml-2">
									Aceito os{' '}
									<span
										className="text-blue-500 cursor-pointer"
										onClick={(ev) => setIsOpenTermos(true)}
									>
										termos de serviços e política de privacidade.
									</span>
								</label>
							</div>

							<div>
								<input
									id="newsletter"
									type="checkbox"
									name="newsletter"
								/>
								<label htmlFor="newsletter" className="ml-2">
									Aceito receber emails sobre as ações e novidades do Instituto.
								</label>
							</div>
						</fieldset>

						<button
							type="submit"
							form="form-informative"
							className="button bg-secondary-dark rounded mt-4 w-24 uppercase font-bold"
							disabled={isSubmitButtonDisabled}
						>
							Enviar
						</button>
					</form>

					{isOpenTermos && (
						<ModalDocuments
							isOpen={isOpenTermos}
							setIsOpen={setIsOpenTermos}
							src="/assets/docs/politica-privacidade-e-termos-de-servicos.pdf"
							title="Política de Privacidade e Termos de Serviços"
						/>
					)}
				</dialog>
			</div>
		</>
	);
}

import CardContentContainer from '@/components/CardContentContainer';
import Container from '@/components/Container';
import NextImage from 'next/image';

export default function ImportantContentSection() {
	return (
		<Container fullWidth>
			<section id="important-contents-section" className="w-full py-8 mt-8">
				<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-y-8 gap-x-8">
					<div className="flex flex-col gap-y-4">
						<h2
							className="text-3xl font-bold"
							tabIndex={0}
						>
							Para ficar por dentro e compartilhar com a galera!
							
						</h2>
						<p tabIndex={0}>
							Tire dúvidas sobre doação de órgãos e tecidos, saiba o que fazer
							quando faltar medicamentos, faça seu cartão doador simbólico e
							personalizado e inscreva-se em nosso Boletim Informativo para
							ficar por dentro de tudo o que acontece no Instituto Deixe Vivo!
						</p>
					</div>

					{/* <figure>
						<picture className="mx-auto w-96">
							<source
								srcSet="/assets/images/illustrations/undraw_work_time.svg"
								media="(min-width: 0px)"
							/>
							<NextImage
								unoptimized
								tabIndex={0}
								aria-label="Uma ilustração que representa duas pessoas sentadas a uma mesa colaborando no trabalho"
								src="/assets/images/illustrations/undraw_work_time.svg"
								alt="Uma ilustração que representa duas pessoas sentadas a uma mesa colaborando no trabalho"
								width={416}
								height={416}
								className={'mx-auto'}
							/>
						</picture>

						<figcaption className="invisible mt-4 text-sm text-center">
							Uma ilustração que representa duas pessoas sentadas a uma mesa
							colaborando no trabalho
						</figcaption>
					</figure> */}
				</div>

				<CardContentContainer />
			</section>
		</Container>
	);
}

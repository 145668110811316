import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	IGeoLocationPositionProps,
	IGeoLocationPositionState,
} from '../../../../declaration';
import { usePermission } from '../use-permission';

export const useGeolocation = (options: IGeoLocationPositionProps) => {
	const [result, setResult] = useState<IGeoLocationPositionState>({
		coords: {
			accuracy: 0,
			altitude: 0,
			altitudeAccuracy: 0,
			heading: 0,
			latitude: 0,
			longitude: 0,
			speed: 0,
		},
		status: undefined,
		timestamp: 0,
		id: 0,
	});

	const { status: geoLocationStatus } = usePermission('geolocation');

	const PositionCallback = useCallback(
		(position: GeolocationPosition): void => {
			setResult((prev) => ({
				...prev,
				coords: position.coords,
				timestamp: position.timestamp,
			}));
		},
		[]
	);

	const PositionErrorCallback = (error: GeolocationPositionError): void => {
		setResult((prev) => ({ ...prev, error: error }));
	};

	const getAgentPosition = (): void => {
		navigator.geolocation.getCurrentPosition(
			PositionCallback,
			PositionErrorCallback,
			options
		);
	};

	const _setWatchId = (id: number): void =>
		setResult((prev) => ({ ...prev, id: id }));

	const watchPosition = useCallback(() => {
		return navigator.geolocation.watchPosition(
			PositionCallback,
			PositionErrorCallback,
			options
		);
	}, [PositionCallback, options]);

	useEffect(
		() => {
			getAgentPosition();

			// switch (geoLocationStatus && geoLocationStatus!.state) {
			// 	case 'denied':
			// 		console.log(geoLocationStatus.state);
			// 		// setResult((prev) => ({ ...prev, status: geoLocationStatus!.state }));
			// 		break;

			// 	case 'granted':
			// 		getAgentPosition();
			// 		// setResult((prev) => ({ ...prev, status: geoLocationStatus!.state }));
			// 		console.log(result);
			// 		break;
			// 	case 'prompt':
			// 		// if (options.watchPosition) {
			// 		//   _setWatchId(watchPosition());
			// 		// }

			// 		break;
			// }

			// return () => {
			// 	// if (result.id) navigator.geolocation.clearWatch(result.id);
			// };
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			// result,
			// geoLocationStatus,
			// getAgentPosition,
			// options.watchPosition,
			// watchPosition,
		]
	);

	return useMemo(() => result, [result]);
};
